import React, {useCallback, useEffect, useMemo, useState} from 'react';
import * as Sentry from '@sentry/browser';
import ReactPlayer from 'react-player';
import {Container, Col, Row} from 'reactstrap';
import {submitForm} from 'utils/forms';
import {useUserState, useUserDispatch} from 'contexts/user';
import {faPlayCircle, faRedo, faSpinnerThird} from '@fortawesome/pro-light-svg-icons';
import './Video.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import track from 'utils/track';

function Video(
  {controls = true, events = [], onEventFired = () => {}, thumbnail, url, disableOverlay = false, label, ...props},
  ref
) {
  const [firedEvents, setFiredEvents] = useState([]);
  const [playing, setPlaying] = useState(props.playing === true);
  const [buffering, setBuffering] = useState(false);
  const [lastHeartbeat, setLastHeartbeat] = useState(0);
  const [ended, setEnded] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const user = useUserState();
  const userDispatch = useUserDispatch();
  const [progress, setProgress] = useState({played: null, playedSeconds: null});
  const defaultThumbnail = useMemo(() => {
    if (thumbnail) return thumbnail;
    const youtubeRegex = /youtu.*\.(?:be|com).*(?:\/|%3D|vi?=)([0-9A-z-_]{11})([%#?&]|$)/gm;
    const match = youtubeRegex.exec(url);
    if (match && match.length > 1) {
      return `https://i.ytimg.com/vi/${match[1]}/maxresdefault.jpg`;
    }
  }, [thumbnail, url]);
  const [overlay, setOverlay] = useState(defaultThumbnail);
  useEffect(() => {
    setOverlay(defaultThumbnail);
  }, [defaultThumbnail]);
  useEffect(() => {
    events &&
      events.length &&
      setFiredEvents(
        [].concat(
          firedEvents,
          events
            .filter(
              (e) =>
                !firedEvents.includes(e.name) &&
                !isNaN(e.pos) &&
                ((e.pos < 1 && e.pos < progress.played) || (e.pos >= 1 && e.pos < progress.playedSeconds))
            )
            .map((event) => {
              try {
                track(event.name, {label: label ? label : url});
                const {registrationId, uuid} = user || {};
                if (process.env.VIDEO_EVENT_FORM_ID && registrationId) {
                  submitForm({registrationId, uuid, event: event.name}, process.env.VIDEO_EVENT_FORM_ID);
                } else {
                  Sentry.withScope((scope) => {
                    scope.setExtras({VIDEO_EVENT_FORM_ID: process.env.VIDEO_EVENT_FORM_ID, user});
                    Sentry.captureException(new Error('Error capturing Video Event'));
                  });
                }
                userDispatch({type: 'ADD_EVENT', payload: event.name});
                onEventFired(event.name);
              } catch (e) {
                Sentry.captureException(e);
                console.error(e);
              }
              return event.name;
            })
        )
      );
  }, [progress.played, events, user]);

  useEffect(() => {
    setPlaying(props.playing);
  }, [props.playing]);

  useEffect(() => {
    const {playedSeconds} = progress;
    if (lastHeartbeat < playedSeconds - 60) {
      setLastHeartbeat(playedSeconds);
      track('Video Content Playing', {position: Math.floor(playedSeconds), label: label ? label : url});
    }
  }, [progress.playedSeconds, lastHeartbeat]);

  const _onProgress = useCallback(
    (e, ...args) => {
      const {onProgress = () => {}} = props;
      const {played, playedSeconds} = e;
      setProgress({played, playedSeconds});
      onProgress.apply(this, [e, args]);
    },
    [props.onProgress]
  );
  const _onPlay = useCallback(
    (...args) => {
      const {onPlay = () => {}} = props;
      setPlaying(true);
      setSeeking(false);
      setEnded(false);
      track('Video Playback Started', {label: label ? label : url});
      onPlay.apply(this, args);
    },
    [props.onPlay, label, url]
  );
  const _onPause = useCallback(
    (...args) => {
      const {onPause = () => {}, pauseThumbnail} = props;
      if (pauseThumbnail || defaultThumbnail) setOverlay(pauseThumbnail || defaultThumbnail);
      setPlaying(false);
      track('Video Playback Paused', {label: label ? label : url});
      onPause.apply(this, args);
    },
    [props.onPause, props.pauseThumbnail, label, url]
  );
  const _onBuffer = useCallback((...args) => {
    setBuffering(true);
  }, []);
  const _onBufferEnd = useCallback((...args) => {
    setBuffering(false);
  }, []);
  const _onEnded = useCallback(
    (...args) => {
      const {onEnded = () => {}, endThumbnail} = props;
      if (endThumbnail || defaultThumbnail) setOverlay(endThumbnail || defaultThumbnail);
      setPlaying(false);
      setEnded(true);
      track('Video Playback Completed', {label: label ? label : url});
      onEnded.apply(this, args);
    },
    [props.onEnded, props.endThumbnail, label, url]
  );
  return (
    <div className="video-wrapper">
      {!disableOverlay && !playing && overlay ? (
        <div
          className="video-overlay"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(34, 34, 34, .5), rgba(34, 34, 34, .5)), url('${overlay}')`
          }}
          onClick={(e) => {
            ended && ref && ref.current && ref.current.seekTo(0);
            setPlaying(true);
            setOverlay(null);
          }}
        >
          <div>
            {buffering ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin />
            ) : (
              <>
                <FontAwesomeIcon icon={ended ? faRedo : faPlayCircle} />
                <br />
                {ended ? 'Replay' : 'Click to Play'}
              </>
            )}
          </div>
        </div>
      ) : null}
      <ReactPlayer
        className="video-player"
        width="100%"
        height="100%"
        controls={controls}
        url={url}
        {...props}
        playing={playing}
        progressInterval={1000}
        onBuffer={_onBuffer}
        onBufferEnd={_onBufferEnd}
        onEnded={_onEnded}
        onPause={_onPause}
        onPlay={_onPlay}
        onProgress={_onProgress}
        ref={ref}
        config={{
          ...props.config,
          youtube: {
            playerVars: {rel: 0}
          }
        }}
      />
    </div>
  );
}

export default React.forwardRef(Video);
