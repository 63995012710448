import React, {Component, useCallback, useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import Video from 'components/Video';
import CheckoutButton from 'components/CheckoutButton';
import Footer from 'components/Elements/Footer';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import wlcLogo from 'static/logo/frn-wlc-logo-white.svg';

import Section, {SectionImage} from 'components/Elements/Section';
import useBeacon from 'utils/useBeacon';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {WlcUpsellMonth} from 'components/SalesCta';
import {TestimonialCircle} from 'components/Testimonial';
import {ImgBulletCard, ImgCard} from 'components/Elements/ImgBullet';
import {recipeTileWlc} from 'data/recipe.json';
import {RecipeTileBox} from 'components/Elements/Tile';

export default function ({product = 'collections', ...props}) {
  useBeacon();
  return (
    <div className="page page-nosocial">
      <PageMeta title="WLC Upsell" description="Make FOOD the foundation of YOUR health" />

      <div className="page-content">
        <Section
          id="section-wlc-header"
          className="section-angle-first"
          innerClass="text-white text-center"
          color="black"
        >
          <Container>
            <Row className="d-flex justify-content-center mb-4">
              <Col>
                <p>
                  <i>Exciting things are on the way!</i>
                  {product === 'fda' ? (
                    <>
                      <br />
                      Your first 14-Day Plant-Powered Accelerator email <br className="d-none d-sm-block d-lg-none" />
                      will reach your inbox in a few minutes.
                    </>
                  ) : null}
                </p>
                <h3 className="mt-3 mb-0">
                  Before you go, do you want a free <br className="d-none d-sm-block d-xl-none" />
                  month in WHOLE Life Club?
                </h3>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <div className="px-3 px-sm-0">
                  <h5 className="section-heading text-uppercase mt-3">
                    Watch This Important Message <br className="d-none d-sm-block d-lg-none" />
                    From Ocean Robbins:
                  </h5>
                </div>
                <Video url="https://www.youtube.com/watch?v=FmR7u3-S35w" label="THC - WLC Upsell" />
              </Col>
            </Row>

            <Row className="text-center mt-5">
              <Col>
                <p>
                  {product === 'collections' ? (
                    <>
                      The [insert health collection purchased] jumpstarts your journey to your best health.{' '}
                      <br className="d-none d-lg-block" />
                      But make sure you don’t slip or lose momentum…
                    </>
                  ) : null}
                  {product === 'fda' ? (
                    <>
                      The 14-Day Plant-Powered Accelerator kicks off your healthy eating journey.{' '}
                      <br className="d-none d-lg-block" />
                      But make sure you don’t slip after 2 weeks…
                    </>
                  ) : null}
                </p>
                <h3 className="mt-0">
                  WHOLE Life Club is the #1 way to make <br className="d-none d-lg-block" />
                  healthy habits stick, <i>for LIFE.</i>
                </h3>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-offer-1" color="gradient-indigo-purple-right">
          <Container>
            <Row className="mt-4">
              <Col>
                <h2 className="section-heading text-white text-center text-uppercase mb-5">One-Time Offer</h2>
                <WlcUpsellMonth noTrees noDisclaimer />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="section-wlc-secret" color="white">
          <Container>
            <Row>
              <Col>
                <p className="text-center text-16 mb-5">
                  <em>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. <br className="d-none d-xl-block" />
                    Their testimonials reflect their unique experience. Your specific results will vary.
                  </em>
                </p>
                <TestimonialCircle id="wlc-christopher" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-kathy-w-short" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-heidi-h" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-robin-short" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-tina-g" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-juanita" imgPosition="right" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-cindy" />
                <hr className="my-5" />
                <TestimonialCircle id="wlc-maureen" imgPosition="right" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-recipes" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">100s of DELICIOUS, HEALTHY RECIPES</h2>
              </Col>
            </Row>
            <Row>
              {recipeTileWlc
                .filter((recipeTileWlc) => recipeTileWlc.id != 'lemon-thyme-brussels-sprouts')
                .map((recipeTileWlc) => (
                  <Col lg="6" className="mb-4">
                    <RecipeTileBox id={recipeTileWlc.id} key={recipeTileWlc.id} recipeTileWlc />
                  </Col>
                ))}
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-2" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Weekly Videos <br className="d-none d-sm-block d-lg-none" />
                  From Ocean Robbins
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img className="img-border rounded" src={require('static/wlc/action-of-the-week.jpg')} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  No one is born with an instruction manual on how to eat and what to do — but when you join the WHOLE
                  Life Club, you can be sure you won’t miss out on the most important information.
                </p>
                <p>
                  These Weekly Guides are like getting a private coaching session with Ocean each week, where he shares
                  the truth on MUST-KNOW topics that can transform your life.
                </p>
                <p>
                  You’ll get dispatches on topics like healthy versus unhealthy fats, adrenals, immune-supporting herbs,
                  adaptogens, fitness hacks, cancer-kicking foods, the truth about vitamins and minerals, practical tips
                  like how to say “no” and stay positive, and so much more.
                </p>
                <p>
                  With Ocean by your side, you’ll stay vibrant and motivated and keep taking action to be your best YOU.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-2" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">Curated (Delicious) Recipes</h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src={require('static/wlc/recipe.png')} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  No more getting stuck in food ruts, eating the same meals over and over. Each week, you’ll get new
                  recipes that dovetail perfectly with Ocean’s weekly guidance.
                </p>
                <p>
                  Discover superstars of nutrition with exciting recipes that showcase new ways to delight your taste
                  buds (and your family, too)!
                </p>
                <p>
                  For example, when you learn about zinc and how it has been found to shorten colds, you’ll get
                  zinc-rich recipes like <i>Ginger Miso Dressing,</i> <i>Creamy Lentil Spinach Dahl,</i> and{' '}
                  <i>Chocolate Almond Mousse.</i>
                </p>
                <p>
                  Or, when the topic is avoiding anemia, you can enjoy adding more healthy non-heme iron to your plate
                  with recipes like <i>Orange-Miso-Glazed Tofu</i> and <i>Chocolate Raspberry Smoothie.</i>
                </p>
                <p>
                  Each recipe is a super-healthy crowd pleaser — always vegan, with gluten-free, soy-free, and salt-free
                  options — and mega-delicious, so everyone can partake.
                </p>
                <p>
                  The instant you join, you’ll have the entire collection of hundreds of amazing WHOLE Life Club recipes
                  at your fingertips.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-3" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Monthly Action Hours <br className="d-none d-sm-block d-xl-none" />
                  with Leading Experts
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src={require('static/wlc/action-hour.png')} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  Would you like front-row seats to the latest scientific discoveries from doctors who are{' '}
                  <i>New York Times</i> best-selling authors and world-famous health experts?
                </p>
                <p>
                  As a WHOLE Life Club member, you’ll be treated to monthly Action Hours with health heroes who can’t
                  wait to share their decades of research and wisdom with you.
                </p>
                <p>
                  Get the scoop on topics such as longer lifespans, eating to defeat cancer, ending food addiction,
                  conquering autoimmune disease, healthy hormones, calming anxiety naturally, habits to avoid heart
                  disease, and more. (And you can ask YOUR questions, too!)
                </p>
                <p>
                  Plus, the instant you join WHOLE Life Club, you’ll have access to our entire library of 50+ Action
                  Hours.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="whole-more-4" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Access to Registered Dieticians and <br className="d-none d-xl-block" />
                  Brilliant <br className="d-none d-md-block d-lg-none" />
                  Health Coaches
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src={require('static/wlc/coaches.png')} />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  Get answers to your most important health and nutrition questions — <em>finally!</em>
                </p>
                <p>
                  If you’ve ever tried to find answers on YouTube or blogs, then you know how frustrating it can be to
                  read 10 articles that all seem to contradict each other. That’s why we created a way for you to get
                  your questions answered by our experienced Community Support team, which includes registered
                  dietitians and certified health coaches. This kind of help can feel like a lifesaver.
                </p>
                <p>Our team is here to offer you exceptional support!</p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage id="whole-more-5" color="white" imgUrl={require('static/wlc/img-community.jpg')}>
          <h2 className="section-heading text-uppercase">
            Community <br className="d-none d-xl-block" />
            (Uplifting Support)
          </h2>
          <p>No naysayers here! We know it can be tough to stick to goals if you’re on your own.</p>
          <p>
            In a world where the status quo revolves around toxic food and encourages us to put up with feeling worse
            each year, we need to surround ourselves with others who share our values of healthy food and making a
            better planet for future generations.
          </p>
          <p>
            In this warm and welcoming group, you’ll find a space to ask questions and cheer each other on as we
            celebrate wins — and we do celebrate often. You simply have to experience for yourself what a difference
            this kind of support will make!
          </p>
        </SectionImage>

        <SectionImage
          id="whole-more-6"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/wlc/1194829220.jpg')}
        >
          <h2 className="section-heading text-uppercase">Cutting-Edge Science</h2>
          <p>
            We are fortunate to live in the most scientifically advanced period in the history of the world. However,
            information overload can make it hard to find accurate, credible sources for the topics you care about.
          </p>
          <p>
            Getting the right information makes all the difference when it comes to topics like cancer, autoimmune
            disorders, heart disease, thyroid health, weight and metabolism, pain, Alzheimer’s, and more.
          </p>
          <p>
            New discoveries are made daily — and you can count on us to keep you up to date with breaking news from
            credible sources.
          </p>
        </SectionImage>

        <SectionImage id="whole-theme" color="white" imgUrl={require('static/wlc/couple-happy-bowls-square.jpg')}>
          <h2 className="section-heading text-uppercase">What You’ll Get</h2>
          <IconList>
            <IconListItem>Five delicious, whole foods, plant-powered recipes per week.</IconListItem>
            <IconListItem>Weekly videos from Ocean Robbins, delivered to your inbox.</IconListItem>
            <IconListItem>
              Monthly Action Hour events with health revolutionaries (including the chance to ask them YOUR questions).
            </IconListItem>
            <IconListItem>Action Checklists to keep you on track.</IconListItem>
            <IconListItem>
              Hundreds of fabulous recipes, with new options delivered to you every single week.
            </IconListItem>
            <IconListItem>
              Access to our team of health coaches & certified nutritionists for all your questions.{' '}
            </IconListItem>
            <IconListItem>A private, moderated community.</IconListItem>
            <IconListItem>Access to the entire library of 100+ videos.</IconListItem>
            <IconListItem>500+ health articles, and much more!</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="whole-why" color="light-gray">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col>
                <h2 className="section-heading text-center mb-5">Why Join WHOLE Life Club?</h2>
                <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-money.jpg')}>
                  <h4 className="section-heading">Save money.</h4>
                  <p>
                    With just a takeout meal or two less each month, your membership will pay for itself. With fewer
                    trips to the doctor and pharmacy aisle, you could save thousands.
                  </p>
                </ImgCard>
                <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-time.jpg')}>
                  <h4 className="section-heading">Save time.</h4>
                  <p>Learn the must-know techniques you were never taught for saving time while cooking.</p>
                  <p>
                    Plus, all recipes can be adapted for gluten-, sugar-, oil-, and/or salt-free diets, so you won’t
                    waste time hunting for special meals you can eat.
                  </p>
                </ImgCard>
                <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-health.jpg')}>
                  <h4 className="section-heading">Save your health.</h4>
                  <p>Don’t miss out on critical health topics that every single person needs to know about.</p>
                  <p>
                    WHOLE Life Club is like a total health encyclopedia, giving you the resources you need when you need
                    them.
                  </p>
                </ImgCard>
                <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-hassle.jpg')}>
                  <h4 className="section-heading">Save yourself hassle.</h4>
                  <p>
                    Whether you’re a seasoned chef or a beginner, you’ll get tips and tricks to help you make cooking
                    and menu planning fun.
                  </p>
                </ImgCard>
                <ImgCard imgColLg="4" textColLg="8" img={require('static/wlc/why-planet.jpg')}>
                  <h4 className="section-heading">Save the planet.</h4>
                  <p>
                    Putting more veggies <br className="d-none d-sm-block d-md-none" />
                    on your plate is one of the best things you can do to save soil and water, reduce pesticides, fight
                    climate change, and preserve the beautiful Earth we call home.
                  </p>
                </ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="section-wlc-offer-2"
          className="section-angle-last"
          color="gradient-indigo-purple-right"
          angle="none"
        >
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center text-uppercase">One-Time Offer</h2>
                <h3 className="mt-0 text-white text-center text-uppercase mb-5">Try WHOLE Life Club For Free Today</h3>
                <WlcUpsellMonth noTrees noDisclaimer />
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <Footer />
    </div>
  );
}
