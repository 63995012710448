import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import CancerSalesIndex from '../../cancer/join';
import track from 'utils/track';

export default function SalesIndex() {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'acc',
        sku: 'acc',
        name: 'Anti-Cancer Collection',
        variant: 'acc-special',
        value: 47,
        currency: 'usd'
      }),
    []
  );
  return <CancerSalesIndex ctaOverride="salesPrice" ctaNoTimer variant="ad" />;
}
