import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import HeartSalesIndex from './join';
import track from 'utils/track';

export default function () {
  const {
    phases: {
      heart: heartSchedule = {
        phase: '',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-HHC',
      sku: 'B-HHC',
      name: 'Heart Health Collection',
      variant: heartSchedule.phase === 'during' ? 'hhc-special' : 'hhc',
      value: heartSchedule.phase === 'during' ? 47 : 97,
      currency: 'usd'
    });
  }, [heartSchedule.phase]);

  return (
    <HeartSalesIndex
      ctaOverride={heartSchedule.phase === 'during' ? 'salesPrice' : 'fullPrice'}
      shareUrl="https://health.foodrevolution.org/heart/join-special/"
    />
  );
}
