import React, {useCallback, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faSquare, faCheckSquare} from '@fortawesome/pro-solid-svg-icons';
import Modal from 'components/Modal';
import logoTrees from 'static/logo/logo-trees-for-future.png';
import {collection} from 'data/thc.json';
import ReactMarkdown from 'react-markdown';
import IconList, {IconListItem} from 'components/Elements/IconList';
import './CheckoutSelection.scss';
import classNames from 'classnames';
import CheckoutButton from 'components/CheckoutButton';

export default function ThcOptions({...props}) {
  return collection.map((collection) => <CheckoutSelection {...collection} key={collection.id} {...props} />);
}

export function CheckoutSelection({
  id,
  name,
  image,
  descriptionList,
  description,
  regularPrice,
  offerPrice,
  recipeHeadline,
  recipes,
  compSlug,
  ...props
}) {
  const CheckCircle = ({checked, ...props}) => (
    <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} size="lg" {...props} />
  );

  return (
    <Card className={classNames(`thc-selection-card thc-selection-card-${id} box-shadow-black-50 mb-5`)}>
      <CardBody className="p-0">
        <Row className="d-flex align-items-stretch m-0">
          <Col xs="12" className="p-4 p-lg-5">
            <ReactMarkdown className="thc-selection-card-name text-h3 text-800 text-center mb-4" source={name} />
            <div className="text-center mb-4">
              <img className="thc-selection-card-image" src={image} />
            </div>
            {props.comp ? <p className="text-center text-strikethrough">Regular Price: ${regularPrice}</p> : null}
            <p
              className={classNames(
                `thc-selection-card-description thc-selection-card-description-${id} text-center mb-4`
              )}
            >
              {description}
            </p>
            {descriptionList ? (
              <IconList>
                {descriptionList.map((description, i) => (
                  <IconListItem className="text-h6" key={i}>
                    {description.descriptionItemImage ? (
                      <div className="d-flex align-items-center">
                        <img
                          className={classNames(`thc-selection-card-item-image`, description.descriptionItemImageClass)}
                          src={description.descriptionItemImage}
                        />
                        <ReactMarkdown className="description" source={description.descriptionItem} />
                      </div>
                    ) : (
                      <ReactMarkdown className="description" source={description.descriptionItem} />
                    )}
                  </IconListItem>
                ))}
              </IconList>
            ) : null}
            <Row className="thc-selection-card-recipes d-flex align-items-stretch text-center m-0">
              {recipes ? (
                <>
                  {recipes.map((recipe) => (
                    <Col sm="6" className="d-flex flex-column flex-grow-1 px-2">
                      <div
                        className={classNames(
                          `imgBulletCard background-white text-black box-shadow-black-50 rounded mb-4 flex-grow-1`
                        )}
                      >
                        <Row className="d-flex align-items-stretch m-0 flex-grow-1">
                          <Col
                            xs="12"
                            lg="5"
                            xl="4"
                            className="thc-selection-card-image background-cover background-image-center px-0"
                            style={{backgroundImage: `url('${recipe.recipeImage}')`}}
                          />
                          <Col
                            xs="12"
                            lg="7"
                            xl="8"
                            className="text-center text-lg-left p-3 p-lg-2 p-xl-3 d-flex flex-column"
                          >
                            <div className="my-auto">
                              <strong>{recipe.recipeName}</strong>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
        {props.comp ? (
          <Row className="d-flex justify-content-center px-4 pb-4 px-lg-5 pb-lg-5">
            <Col xs="12" lg="6">
              <CheckoutButton color="upsell-check" sku="frs" slug={compSlug} price={offerPrice}>
                CHOOSE JUST THIS ONE — FREE
              </CheckoutButton>
            </Col>
          </Row>
        ) : null}
      </CardBody>
    </Card>
  );
}

export function FreeSelection({...props}) {
  return (
    <Row className="d-flex align-items-stretch">
      {collection.map((collection) => (
        <FreeSelectionItem {...collection} key={collection.id} />
      ))}
    </Row>
  );
}

export function FreeSelectionItem({
  id,
  name,
  image,
  descriptionList,
  description,
  regularPrice,
  offerPrice,
  recipeHeadline,
  recipes,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false); // The parameter (false) is the default value
  const onClick = useCallback(() => setIsOpen(true), []);
  // A function is used in the below callback to get the latest value of isOpen, useCallback "memoizes"
  // the function which increases performance but could cause data to be out of date.
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []); //
  return (
    <Col xs="12" lg="6" className={classNames(`thc-selection-option thc-selection-option-${id}`)}>
      <Row className="d-flex align-items-stretch text-center">
        <Col xs="12" className="d-flex flex-column flex-grow-1">
          <div
            className={classNames(
              `imgBulletCard background-white text-black box-shadow-black-50 p-4 rounded mb-4 flex-grow-1`
            )}
          >
            <Row className="d-flex align-items-center m-0">
              <Col xs="12">
                <p>
                  <strong>{name}</strong>
                </p>
                <img className="thc-selection-card-image mb-3" src={image} />
                <Button color="upsell-check" onClick={onClick}>
                  Add to Order — Free
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <Container className="px-sm-4 pt-0 pb-5 text-center" fluid>
          <Row>
            <Col>
              <p className="text-h4 px-md-3 px-lg-0">
                Yes, add <strong>{name}</strong> to my <br className="d-none d-lg-block" />
                Empowerment Package order for free.
              </p>
              <div className="product-image mb-4">
                <img className="thc-selection-card-image" src={image} />
              </div>
              <Row className="d-flex justify-content-center">
                <Col lg="10" xl="8">
                  <Button className="text-h4 mb-3" color="cta" block>
                    Confirm
                  </Button>
                </Col>
              </Row>
              <div>
                <Button className="text-small" color="upsell-gray">
                  No thanks, I want to go back and make a different selection
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
    </Col>
  );
}

export function CheckoutFinalSelection({
  offerSelection = '[offerSelectionName]',
  price = 0,
  disabled = true,
  ...props
}) {
  return (
    <div className="thc-selection-card text-center">
      <Card
        className={classNames(
          `box-shadow-black-50 border-dashed border-3 mb-3`,
          disabled ? 'border-color-green-50' : 'border-color-green-100'
        )}
      >
        <CardBody className="p-5">
          <Row>
            <Col xs="12">
              <p>Your Selection:</p>
              <h4 className="section-heading">{offerSelection}</h4>
              <p>
                Clicking “CONTINUE” will add the {offerSelection} to your purchase now,{' '}
                {price > 0 ? <>for an additional ${price}.</> : 'for FREE.'}
              </p>
              {price > 0 ? (
                <p>
                  <strong>Your purchase comes with a risk-free, 60-day, money-back guarantee.</strong>
                </p>
              ) : null}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mt-4">
            <Col xs="12" md="10" lg="8" xl="6">
              <Button className="text-white text-800 my-auto" color="cta" block disabled={disabled}>
                Continue
                <FontAwesomeIcon className="ml-3" icon={faChevronRight} />
              </Button>
            </Col>
          </Row>

          {disabled ? (
            <Row>
              <Col>
                <p className="mt-3">
                  <strong>In order to continue, you must choose your free product above.</strong>
                </p>
              </Col>
            </Row>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export function ThcBonusList({...props}) {
  return collection.map((collection) => <ThcBonusItem {...collection} key={collection.id} {...props} />);
}

export function ThcBonusItem({
  id,
  name,
  image,
  descriptionList,
  description,
  regularPrice,
  offerPrice,
  recipeHeadline,
  recipes,
  compSlug,
  ...props
}) {
  return (
    <Card className="thc-bonus-item box-shadow-black-50 mb-5 p-4">
      <CardBody>
        <ReactMarkdown className="thc-bonus-item-name text-h3 text-800 text-center mb-3" source={name} />
        <div className="text-center mb-0">
          <img className="thc-selection-card-image" src={image} />
        </div>
        <Row className="d-flex justify-content-center m-0">
          <Col lg="9">
            {descriptionList ? (
              <IconList>
                {descriptionList.map((description, i) => (
                  <IconListItem key={i}>
                    <ReactMarkdown className="description" source={description.descriptionItem} />
                  </IconListItem>
                ))}
              </IconList>
            ) : null}
            <Row className="thc-selection-card-recipes d-flex align-items-stretch text-center m-0">
              {recipes ? (
                <>
                  {recipes.map((recipe) => (
                    <Col md="6" className="d-flex flex-column flex-grow-1 px-2" key={recipe.recipeName}>
                      <div
                        className={classNames(
                          `imgBulletCard background-white text-black box-shadow-black-50 rounded mb-4 flex-grow-1 d-flex flex-column`
                        )}
                      >
                        <Row className="d-flex align-items-stretch flex-grow-1 m-0">
                          <Col
                            xs="12"
                            lg="5"
                            xl="4"
                            className="thc-selection-card-recipes-image background-cover background-image-center px-0"
                            style={{backgroundImage: `url(${recipe.recipeImage})`}}
                          />
                          <Col
                            xs="12"
                            lg="7"
                            xl="8"
                            className="d-flex flex-column text-center text-lg-left p-3 p-lg-2 p-xl-3 flex-grow-1"
                          >
                            <p className="my-auto">
                              <strong>{recipe.recipeName}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </>
              ) : null}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export function CollectionRecipes({id, className = null, ...props}) {
  const collectionItem = collection.find((e) => e.id === id);
  return collectionItem ? <CollectionRecipeContent className={className} {...collectionItem} {...props} /> : null;
}

function CollectionRecipeContent({id, recipes, className, ...props}) {
  return (
    <div className={classNames(`collection-recipes-wrap`, className)}>
      <Row className="d-flex align-items-stretch text-center m-0">
        {recipes ? (
          <>
            {recipes.map((recipe) => (
              <Col md="6" className="d-flex flex-column flex-grow-1 px-2" key={recipe.recipeName}>
                <div
                  className={classNames(
                    `imgBulletCard background-white text-black box-shadow-black-50 rounded mb-4 flex-grow-1 d-flex flex-column`
                  )}
                >
                  <Row className="d-flex align-items-stretch flex-grow-1 m-0">
                    <Col
                      xs="12"
                      lg="5"
                      xl="4"
                      className="collection-recipes-image background-cover background-image-center px-0"
                      style={{backgroundImage: `url(${recipe.recipeImage})`}}
                    />
                    <Col
                      xs="12"
                      lg="7"
                      xl="8"
                      className="d-flex flex-column text-center text-lg-left p-3 p-lg-2 p-xl-3 flex-grow-1"
                    >
                      <p className="my-auto">
                        <strong>{recipe.recipeName}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </>
        ) : null}
      </Row>
    </div>
  );
}

export function CollectionSpeaker({id, className = null, borderColor = 'black-15', ...props}) {
  const collectionItem = collection.find((e) => e.id === id);
  return collectionItem ? (
    <CollectionSpeakerContent className={className} borderColor={borderColor} {...collectionItem} {...props} />
  ) : null;
}

function CollectionSpeakerContent({id, speakers, className, borderColor, ...props}) {
  return (
    <div className={classNames(`collection-speakers-wrap`, className)}>
      {speakers ? (
        <>
          {speakers.map((speaker) => (
            <div className={classNames(`collection-speaker-item collection-speaker-border-${borderColor}`)}>
              <Row className="align-items-center justify-content-center">
                <Col xs="10" sm="8" lg="3" className="mb-4 mb-lg-0">
                  <img className="collection-speaker-image rounded-circle" src={speaker.speakerImage} />
                </Col>
                <Col lg="9">
                  <ReactMarkdown className="collection-speaker-name text-h4 text-800" source={speaker.speakerName} />
                  <ReactMarkdown className="collection-speaker-title text-h5 text-800" source={speaker.speakerLesson} />
                  <ReactMarkdown className="collection-speaker-bio" source={speaker.speakerBio} />
                </Col>
              </Row>
            </div>
          ))}
        </>
      ) : null}
    </div>
  );
}
