import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import SalesIndex from './join';
import track from 'utils/track';

export default function () {
  const {
    phases: {
      default: defaultSchedule = {
        phase: '',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'fda',
      sku: 'fda',
      name: '14-Day Plant-Powered Accelerator',
      variant: defaultSchedule.phase === 'during' ? 'fda-special' : 'fda',
      value: defaultSchedule.phase === 'during' ? 7 : 47,
      currency: 'usd'
    });
  }, [defaultSchedule.phase]);

  return <SalesIndex ctaOverride={defaultSchedule.phase === 'during' ? 'salesPrice' : 'fullPrice'} />;
}
