import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitCancer({version, ...props}) {
  const {isOpen, toggle} = useExitIntent(30000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading">Before you press X…</h4>
          <p>
            Will your next meal have the antioxidants <br className="d-none d-sm-block" />
            and nutrients you need to protect yourself?
          </p>
          <p>
            Get the <b>Anti-Cancer Collection</b> for just {version === 'salesPrice' ? '$47' : '$97'}
          </p>
          <Row className="d-flex align-items-center mt-5">
            <Col xs="12" lg="6" className="mb-4 mb-lg-0">
              <Button
                className="text-uppercase text-white btn-exit-orange btn-exit-cancer"
                color="cta"
                onClick={toggle}
                block
                activeClass="active"
                spy={true}
                offset={0}
                to="acc-cta"
                smooth={true}
                href="#"
                tag={Link}
              >
                Get the Collection Now
              </Button>
            </Col>
            <Col xs="12" lg="6">
              <Button
                className="text-uppercase text-800 text-white btn-exit-cancer"
                color="exit-gray"
                onClick={toggle}
                block
              >
                No Thanks
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className="exit-modal-image"
            style={{backgroundImage: `url('${require('static/backgrounds/1306672291-exit.jpg')}')`}}
          />
        </Col>
      </Row>
    </Modal>
  );
}
