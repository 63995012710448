import React, {Component, useCallback, useEffect} from 'react';
import {Button, Card, CardBody, Col, Container, Row} from 'reactstrap';
import PageMeta from 'components/PageMeta';
import Video from 'components/Video';
import Footer from 'components/Elements/Footer';
import logoTrees from 'static/logo/logo-trees-for-future-white.png';
import Section, {SectionImage} from 'components/Elements/Section';
import useBeacon from 'utils/useBeacon';
import CheckoutSelection, {CheckoutFinalSelection, FreeSelection} from 'components/Thc/CheckoutSelection';
import ThcOptions from 'components/Thc/CheckoutSelection';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CheckoutButton from 'components/CheckoutButton';
import {faCartPlus, faLock} from '@fortawesome/pro-light-svg-icons';
import {Link} from 'react-scroll';
import {SalesDisclaimer} from 'components/SalesCta';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {ImgBulletCard} from 'components/Elements/ImgBullet';

export default function ({product = 'collection'}) {
  useBeacon();
  return (
    <div className="page page-nosocial">
      <PageMeta title="Add the Total Health Collection to your order" description="THC upsell page" />

      <div className="page-content">
        <Section id="thc-header" innerClass="text-white text-center pt-4" color="black">
          <Container>
            <Row>
              <Col>
                {product === 'collection' ? (
                  <>
                    <p>
                      <b>
                        Thank you for taking a stand for your health! <br className="d-none d-sm-block d-md-none" />
                        You’ve made a great choice.
                      </b>
                    </p>
                    <p>
                      <i>Before you go…</i>
                    </p>
                    <h4 className="mt-0">
                      <b>
                        Get all four Health Collections <br className="d-none d-sm-block d-lg-none" />
                        for just $47. (Save 83%)
                      </b>
                    </h4>
                  </>
                ) : null}

                {product === 'frs21' ? (
                  <>
                    <p>
                      <strong>
                        Thanks for getting the 2021 Food Revolution Summit.{' '}
                        <br className="d-none d-sm-block d-lg-none" />
                        You’ve made a great choice!
                      </strong>
                    </p>
                    <p>
                      And, as promised, you can{' '}
                      <span className="background-green px-1 rounded">
                        select your totally free bonus <br className="d-none d-sm-block d-lg-none" />
                        at the bottom of this page
                      </span>{' '}
                      — any one <br className="d-none d-lg-block" />
                      of the four health collections that make up our brand new Total Health Collection.
                    </p>
                    <p>
                      <strong>Or, you can get the entire Total Health Collection for just $47.</strong>
                    </p>
                  </>
                ) : null}

                {product === 'tntg' ? (
                  <>
                    <p>
                      <strong>
                        Thank you for taking a stand to be part of the SOLUTION! You’ve made a great choice.
                      </strong>
                    </p>
                    <p>
                      And, as promised, you can{' '}
                      <span className="background-green px-1 rounded">
                        select your totally free bonus <br className="d-none d-sm-block d-lg-none" />
                        at the bottom of this page
                      </span>{' '}
                      — any one <br className="d-none d-lg-block" />
                      of the four health collections that make up Food Revolution Network’s Total Health Collection.
                    </p>
                    <p>
                      <strong>Or, you can get the entire Total Health Collection for just $47.</strong>
                    </p>
                  </>
                ) : null}

                <p className="mt-4">Watch this video to learn more:</p>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video url="https://www.youtube.com/watch?v=nUqcMWH1xiY" label="THC - THC Upsell" playing />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <p>
                  <b>
                    <i>
                      Take a moment to read the following as this special opportunity{' '}
                      <br className="d-none d-md-block" />
                      WON’T be available again after you close this page.
                    </i>
                  </b>
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="thc-upgrade-1" color="white">
          <Container>
            <Row className="text-center">
              <Col>
                <img
                  className="mb-1"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  style={{maxHeight: '75px'}}
                />
                <h2 className="section-heading">Total Health Collection</h2>
                <h4 className="mt-0">
                  None of us can afford to leave <br className="d-none d-sm-block d-lg-none" />
                  our health up to chance.
                </h4>
                <h4 className="m-0">
                  Get the facts you need on four of the most <br className="d-none d-md-block d-xl-none" />
                  important health topics in the world.
                </h4>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="thc-upgrade-2"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1288032384.jpg')}
        >
          {product === 'collection' ? (
            <>
              <h5 className="section-heading">
                Upgrade to get all <i>four</i> Health Collections
              </h5>
              <p>
                Already purchased:
                <br />
                <b>[Insert the one they just got]</b>
              </p>
              <h5>Now you can add: [Show the other three]</h5>
              <IconList>
                <IconListItem>Immune Health Collection</IconListItem>
                <IconListItem>Anti-Cancer Collection </IconListItem>
                <IconListItem>Heart Health Collection</IconListItem>
                <IconListItem>Brain Health Collection </IconListItem>
              </IconList>
              <h4>Yours Today For Just $47</h4>
            </>
          ) : null}

          {product === 'tntg' ? (
            <>
              <p>
                Each of the unique health collections within the Total Health Collection contains a specially curated
                bundle of some of the most popular and life-changing health content we created in our first 10 years,
                including summit interviews, Q&A-based action hours with world-leading experts, informative guidebooks,
                personal workbooks, and amazingly delicious and nutritious recipes that will add vitality and yumminess
                to your life.
              </p>
              <p>
                Each one is well worth the $97 regular price — and an incredible value when you get yours absolutely
                free.
              </p>
              <p className="mb-0">
                <strong>But, if you want maximum results, then…</strong>
              </p>
            </>
          ) : null}
        </SectionImage>

        <Section id="thc-info" color="light-gray">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center">
                  Be Informed. <br className="d-none d-sm-block d-md-none" />
                  Make the Best Choices.
                </h2>
                <h3 className="text-center mt-0">
                  Take control of your future with the <br className="d-none d-md-block d-xl-none" />
                  Total Health Collection.
                </h3>
                <div className="thc-product-image text-center mb-4">
                  <img
                    className="thc-selection-card-image"
                    src="https://cdn.foodrevolution.org/thc/thc-product-image-v20220203.png"
                  />
                </div>
                <p className="text-center">
                  {product === 'collection' ? (
                    <>
                      Regular Price for the three new ones: <del>$291</del>
                    </>
                  ) : (
                    <>
                      Regular Price for all four: <del>$388</del>
                    </>
                  )}
                </p>
                <div className="upsell-box-confirm text-center mb-3">
                  <div className="mb-3">
                    <CheckoutButton className="" sku="thc" price="47" slug="thc-upsell">
                      {product === 'collection' ? (
                        <>
                          Add all three to <br className="d-sm-none" />
                          Cart Now for $47
                        </>
                      ) : (
                        <>
                          Add ALL to Cart Now <br className="d-lg-none" />
                          for a total of just $47
                        </>
                      )}
                    </CheckoutButton>
                  </div>
                  <div className="upsell-box-text">
                    Clicking “ADD TO ORDER” will add the Total Health Collection <br className="d-none d-lg-block" />
                    to your purchase right now, for an additional one-time $47.
                  </div>
                </div>
                <SalesDisclaimer className="text-center mb-5" />
                <p>
                  With the Total Health Collection, you can have confidence in your future health. You’ll have the
                  insights to provide yourself and your loved ones with the nourishment that supports a healthy brain, a
                  healthy heart, a healthy immune system, and cancer prevention.
                </p>
                <p>
                  It includes 12 of our <i>most</i> popular health-focused Food Revolution Summit interviews, four
                  members-only WHOLE Life Action Hours, four guidebooks, and 24 of our most beloved recipes.
                </p>
                <h4 className="my-4">
                  Here’s what you’ll get with each collection
                  {product === 'collection' ? (
                    <>
                      {' '}
                      that you’ll add <br className="d-none d-lg-block d-xl-none" />
                      to your order
                    </>
                  ) : null}
                  :
                </h4>
                {product === 'collecton' ? (
                  /* needs to exclude the one they just bought */ <ThcOptions />
                ) : (
                  <ThcOptions />
                )}
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="thc-recap" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">
                  {product === 'collection' ? (
                    <>Add to the [insert name of Health collection they just bought] for a Total of:</>
                  ) : (
                    <>A Recap of What You Get With the Total Health Collection</>
                  )}
                </h2>
                <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-headshots.png" imgColLg="5" textColLg="7">
                  <h4 className="section-heading mb-2">All 16 Interviews (mp3 and video)</h4>
                  <p className="text-h5 mb-0">With Leading Health Experts</p>
                </ImgBulletCard>
                <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-transcripts.png" imgColLg="5" textColLg="7">
                  <h4 className="section-heading mb-2">All 16 Written Transcripts</h4>
                  <p className="text-h5 mb-0">With Links To Scientific Studies</p>
                </ImgBulletCard>
                <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-guidebooks.png" imgColLg="5" textColLg="7">
                  <h4 className="section-heading mb-2">All 4 Health Guidebooks</h4>
                  <p className="text-h5 mb-0">To Ensure You Don’t Have Knowledge Gaps</p>
                </ImgBulletCard>
                <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-workbooks.png" imgColLg="5" textColLg="7">
                  <h4 className="section-heading mb-2">All 4 Workbooks</h4>
                  <p className="text-h5 mb-0">To Retain What You Learn & Master the Material</p>
                </ImgBulletCard>
                <ImgBulletCard img="https://cdn.foodrevolution.org/thc/thc-recipes.png" imgColLg="5" textColLg="7">
                  <h4 className="section-heading mb-2">All 28 Healthy, Plant-based Recipes</h4>
                  <p className="text-h5 mb-0">For Delicious Inspiration & Maximum Flavor</p>
                </ImgBulletCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section
          id="thc-cta"
          color="gradient-indigo-purple-right"
          className={product === 'collection' ? 'section-angle-last' : 'null'}
          angle={product === 'collection' ? 'none' : 'bottom'}
        >
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading text-center text-white">This Page Only!</h3>
                <div className="cta-sales cta-sales-thc">
                  <div className="offer-box background-white border-dashed border-green border-2 offer-box-shadow px-3 py-4 px-sm-4 px-md-5 py-md-5">
                    <Row className="d-flex justify-content-center mb-4">
                      <Col lg="10">
                        <h4 className="section-heading text-center">
                          Get the Total Health Collection, <br className="d-none d-sm-block d-lg-none" />
                          Today Only $47
                        </h4>
                        <div className="thc-product-image text-center mb-4">
                          <img
                            className="thc-selection-card-image"
                            src="https://cdn.foodrevolution.org/thc/thc-product-image-v20220203.png"
                          />
                        </div>
                        <p>
                          To separately get all four of the components in the Total Health Collection, you’d pay $388
                          ($97 each). But, on this page only, as our thank you to you for your decision to get the
                          [insert Product Name], we’re offering the Total Health Collection to you for <b>only $47.</b>
                        </p>
                        <p>
                          <b>That’s an {product === 'collection' ? '83%' : '88%'} discount!</b>
                        </p>
                        <p className="mb-0">
                          <i>But, you have to act fast because this page is the only time you’ll get this chance.</i>{' '}
                          Click the button below to add the Total Health Collection to your order right now.
                        </p>
                      </Col>
                    </Row>
                    <div className="upsell-box-confirm text-center mb-3">
                      <div className="mb-3">
                        <CheckoutButton
                          className="offer-box-button offer-box-button-upsell"
                          sku="B-THC"
                          price="47"
                          slug="frs21-thc"
                        >
                          <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Yes, Add to Order
                        </CheckoutButton>
                      </div>
                      <div className="upsell-box-text">
                        Clicking “ADD TO ORDER” will add the Total Health Collection{' '}
                        <br className="d-none d-lg-block" />
                        to your purchase right now, for an additional one-time $47.
                      </div>
                    </div>

                    {product === 'collection' ? null : (
                      <div className="upsell-box-no text-center">
                        <Link
                          className="btn btn-link text-underline"
                          activeClass="active"
                          spy={true}
                          offset={0}
                          to="thc-offer"
                          smooth={true}
                          href="#"
                        >
                          Or, select your free bonus below.
                        </Link>
                      </div>
                    )}

                    <SalesDisclaimer className="text-center" />
                  </div>

                  <Row className="trees-for-future text-white d-flex align-items-center justify-content-center mt-5">
                    <Col xs="11" lg="8" className="mb-4 mb-lg-0">
                      <h6 className="section-only-heading text-center text-lg-left text-400">
                        For every new order, we make a donation to Trees for the Future, enabling them to plant another
                        organic fruit or nut tree in a low-income community.
                      </h6>
                    </Col>
                    <Col xs="11" lg="3" className="text-center">
                      <img className="logo-trees" src={logoTrees} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        {product === 'collection' ? null : (
          <Section id="thc-offer" className="section-angle-last" color="light-gray" angle="none">
            <Container>
              <Row>
                <Col>
                  <h5 className="section-heading">Don’t want the Total Health Collection?</h5>
                  <p>
                    <strong>Select your free gift below.</strong>
                  </p>
                  <p className="mb-4">
                    Remember, if you don’t{' '}
                    <Link
                      className="text-underline"
                      activeClass="active"
                      spy={true}
                      offset={0}
                      to="thc-cta"
                      smooth={true}
                      href="#"
                    >
                      get the Total Health Collection today
                    </Link>
                    , you’ll be passing up on the option to get it for the lowest price we’ll ever offer it for (just
                    $47). But you can still add any ONE of the Health Collections to your Empowerment Package order, for
                    free.
                  </p>
                  <FreeSelection />
                </Col>
              </Row>
            </Container>
          </Section>
        )}
      </div>

      <Footer />
    </div>
  );
}
