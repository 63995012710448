import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import BrainSalesIndex from './join';

export default function () {
  useEffect(() => {
    navigate('/brain/join-special/' + location.search);
  }, []);
  return <BrainSalesIndex />;
}
