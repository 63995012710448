import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import HeartSalesIndex from './join';
import track from 'utils/track';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'B-HHC',
        sku: 'B-HHC',
        name: 'Heart Health Collection',
        variant: 'hhc-special',
        value: 7,
        currency: 'usd'
      }),
    []
  );
  return <HeartSalesIndex ctaOverride="salesPrice" ctaNoTimer />;
}
