import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import BrainSalesIndex from '../../brain/join';
import track from 'utils/track';

export default function SalesIndex() {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'bhc',
        sku: 'bhc',
        name: 'Brain Health Collection',
        variant: 'bhc-special',
        value: 47,
        currency: 'usd'
      }),
    []
  );
  return <BrainSalesIndex ctaOverride="salesPrice" ctaNoTimer variant="ad" />;
}
