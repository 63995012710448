import React from 'react';
import {FacebookShareButton, TwitterShareButton, PinterestShareButton} from 'react-share';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faXTwitter} from '@fortawesome/free-brands-svg-icons/faXTwitter';
import {faPinterestP} from '@fortawesome/free-brands-svg-icons/faPinterestP';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import './ShareButtons.scss';
const {SITE_URL} = process.env;

export default function ShareButtons({
  shareUrl,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = undefined,
  pinterestDescription,
  pinterestImage,
  ...props
}) {
  return (
    <>
      {props.floating ? (
        <div className="social-share social-share-floating">
          <ShareButtonsList
            shareUrl={shareUrl}
            shareImg={shareImg}
            facebookDescription={facebookDescription}
            twitterDescription={twitterDescription}
            pinterestDescription={pinterestDescription}
            pinterestImage={pinterestImage}
            twitterHashtags={twitterHashtags}
            {...props}
          />
        </div>
      ) : (
        <>
          {props.shareText ? (
            <div className="social-share-copy d-flex align-items-center justify-content-center">
              <div className="social-share-text mb-2 mb-sm-0 mr-sm-2">Share on social:</div>
              <div className="social-share d-flex align-items-center justify-content-center">
                <ShareButtonsList
                  shareUrl={shareUrl}
                  shareImg={shareImg}
                  facebookDescription={facebookDescription}
                  twitterDescription={twitterDescription}
                  pinterestDescription={pinterestDescription}
                  pinterestImage={pinterestImage}
                  twitterHashtags={twitterHashtags}
                  noDescription
                  {...props}
                />
              </div>
            </div>
          ) : (
            <div className={classNames('social-share d-flex align-items-center', props.alignment)}>
              <ShareButtonsList
                shareUrl={shareUrl}
                shareImg={shareImg}
                facebookDescription={facebookDescription}
                twitterDescription={twitterDescription}
                pinterestDescription={pinterestDescription}
                pinterestImage={pinterestImage}
                twitterHashtags={twitterHashtags}
                noDescription
                {...props}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

ShareButtons.defaultProps = {
  alignment: 'justify-content-center justify-content-lg-end'
};

export function ShareButtonsList({
  shareUrl = `${SITE_URL}`,
  shareImg,
  facebookDescription,
  twitterDescription,
  twitterHashtags = [''],
  pinterestDescription,
  pinterestImage,
  noDescription = false,
  ...props
}) {
  return (
    <>
      {noDescription ? null : (
        <div className="social-button">
          <div className="btn btn-social btn-social-share">Shares</div>
        </div>
      )}

      <FacebookShareButton className="social-button" url={shareUrl} quote={facebookDescription}>
        <div className="btn btn-social btn-social-facebook">
          <FontAwesomeIcon className="social-button-icon" icon={faFacebookF} />
          <span className="btn-social-description btn-social-description-facebook d-none d-md-inline">Facebook</span>
        </div>
      </FacebookShareButton>

      <TwitterShareButton
        className="social-button"
        url={shareUrl}
        via="afoodrevolution"
        hashtags={twitterHashtags}
        title={twitterDescription}
      >
        <div className="btn btn-social btn-social-twitter">
          <FontAwesomeIcon className="social-button-icon" icon={faXTwitter} />
          <span className="btn-social-description btn-social-description-twitter d-none d-md-inline">Twitter</span>
        </div>
      </TwitterShareButton>

      <PinterestShareButton
        className="social-button"
        url={shareUrl}
        media={pinterestImage}
        description={pinterestDescription}
      >
        <div className="btn btn-social btn-social-pinterest">
          <FontAwesomeIcon className="social-button-icon" icon={faPinterestP} />
          <span className="btn-social-description btn-social-description-pinterest d-none d-md-inline">Pinterest</span>
        </div>
      </PinterestShareButton>
    </>
  );
}
