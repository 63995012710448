import React from 'react';
import {Head} from 'react-static';
const {SITE_URL} = process.env;

export default function PageMeta({
  url = `${SITE_URL}`,
  ogImage = 'https://cdn.foodrevolution.org/hhc/opengraph/hhc-open-graph-202107-masterclass-v3.jpg',
  title = 'Total Health Collection',
  description = 'An easy intro course to become the healthiest version of you.'
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@afoodrevolution" />
      <meta property="twitter:image" content={ogImage} />
      <meta property="fb:app_id" content="1685199371775570" />
      <meta property="og:site_name" content="Health Collections" />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
    </Head>
  );
}
