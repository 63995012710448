import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import BrainSalesIndex from './join';
import track from 'utils/track';

export default function () {
  const {
    phases: {
      cancer: cancerSchedule = {
        phase: '',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-ACC',
      sku: 'B-ACC',
      name: 'Anti-Cancer Collection',
      variant: cancerSchedule.phase === 'during' ? 'acc-special' : 'acc',
      value: cancerSchedule.phase === 'during' ? 47 : 97,
      currency: 'usd'
    });
  }, [cancerSchedule.phase]);

  return (
    <BrainSalesIndex
      ctaOverride={cancerSchedule.phase === 'during' ? 'salesPrice' : 'fullPrice'}
      shareUrl="https://health.foodrevolution.org/cancer/join-special/"
    />
  );
}
