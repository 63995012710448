import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import ImmunitySalesIndex from '../../immunity/join';
import track from 'utils/track';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'ihc',
        sku: 'ihc21',
        name: 'Immune Health Collection',
        variant: 'ihc21-special',
        value: 47,
        currency: 'usd'
      }),
    []
  );
  return <ImmunitySalesIndex ctaOverride="salesPrice" ctaNoTimer noExitIntent variant="ad" />;
}
