import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitBrain({version, ...props}) {
  const {isOpen, toggle} = useExitIntent(30000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          {version === 'salesPrice' ? (
            <>
              <h4 className="section-heading">Before you go!</h4>
              <p>
                <b>Treat your brain well AND save money doing it.</b>
              </p>
              <p>
                Don’t miss your chance to learn the <b>best foods for brain health</b> from the world’s top experts.
              </p>
            </>
          ) : (
            <>
              <h4 className="section-heading">Before you go!</h4>
              <p>
                Don’t miss your chance to learn the <b>best foods for brain health</b> from the world’s top experts.
              </p>
            </>
          )}
          <Button className="btn-exit text-uppercase text-white text-h5" color="cta" onClick={toggle} block>
            {version === 'salesPrice' ? 'SAVE 50% NOW' : 'Learn More'}
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div className="exit-modal-image background-image-1344935592" />
        </Col>
      </Row>
    </Modal>
  );
}
