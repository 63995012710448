import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import BrainSalesIndex from './join';
import track from 'utils/track';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'B-BHC',
        sku: 'B-BHC',
        name: 'Brain Health Collection',
        variant: 'bhc-special',
        value: 7,
        currency: 'usd'
      }),
    []
  );
  return <BrainSalesIndex ctaOverride="salesPrice" ctaNoTimer />;
}
