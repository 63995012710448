import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import BrainSalesIndex from './join';
import track from 'utils/track';

export default function () {
  const {
    phases: {
      brain: brainSchedule = {
        phase: '',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-BHC',
      sku: 'B-BHC',
      name: 'Brain Health Collection',
      variant: brainSchedule.phase === 'during' ? 'bhc-special' : 'bhc',
      value: brainSchedule.phase === 'during' ? 47 : 97,
      currency: 'usd'
    });
  }, [brainSchedule.phase]);

  return (
    <BrainSalesIndex
      ctaOverride={brainSchedule.phase === 'during' ? 'salesPrice' : 'fullPrice'}
      shareUrl="https://health.foodrevolution.org/brain/join-special/"
    />
  );
}
