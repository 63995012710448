import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import CancerSalesIndex from './join';

export default function () {
  useEffect(() => {
    navigate('/cancer/join-special/' + location.search);
  }, []);
  return <CancerSalesIndex />;
}
