import React, {useEffect} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';

export default function SalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(defaultSchedule.salesEnd).getTime();

  return (
    <div className="page d-flex flex-column">
      <PageMeta />
      <div className="page-content">
        <Header className="background-purple header-wrap" logoWhite style="dark" />
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons floating />
      </div>
    </div>
  );
}
