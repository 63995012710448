import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import classNames from 'classnames';
import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitImmunity({version = 'fullPrice', ...props}) {
  const {isOpen, toggle} = useExitIntent(30000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          {version === 'salesPrice' ? (
            <>
              <h4 className="section-heading">Wait! This 50% savings is only available for a limited time.</h4>
              <p>Don’t leave your immune health up to chance. Get the knowledge you need to optimize your wellness.</p>
            </>
          ) : (
            <>
              <h4 className="section-heading">Before you go!</h4>
              <p>
                Don’t miss your opportunity to learn from the world’s top immune health experts about what to eat and do
                for a strong immune system.
              </p>
              <p>
                <i>Plus, get delicious immune health-supporting recipes!</i>
              </p>
            </>
          )}

          <Button className="btn-exit text-uppercase text-white text-h5" color="cta" onClick={toggle} block>
            Learn More
          </Button>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className={classNames(
              `exit-modal-image background-image-${version === 'salesPrice' ? '1051690906' : '1304724450'}`
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
}
