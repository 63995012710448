import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import HeartSalesIndex from './join';

export default function () {
  useEffect(() => {
    navigate('/heart/join-special/' + location.search);
  }, []);
  return <HeartSalesIndex />;
}
