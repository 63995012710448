import React, {useEffect} from 'react';
import {Col, Container, Input, Row} from 'reactstrap';
import classNames from 'classnames';
import {Link} from 'react-scroll';
import ABTest, {DebugMenu} from 'react-controlled-ab';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';
import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import track from 'utils/track';
import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import Section, {SectionImage} from 'components/Elements/Section';
import Video from 'components/Video';
import CountdownTimer from 'components/Elements/CountdownTimer';
import CheckoutButton from 'components/CheckoutButton';
import {HhcSales47, HhcSales97, SalesDisclaimer} from 'components/SalesCta';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {faExclamationTriangle, faQuestionCircle, faTimes} from '@fortawesome/pro-light-svg-icons';
import {ImgBulletCard, ImgCard} from 'components/Elements/ImgBullet';
import {collection} from 'data/thc.json';
import {CollectionRecipes, CollectionSpeaker} from 'components/Thc/CheckoutSelection';
import GuaranteeBox from 'components/Guarantee';
import FaqList from 'components/FaqList';
import {faqHHC} from 'data/faq.json';
import ModalExitHeart from 'components/Modals/ModalExitHeart';

export default function HeartSalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  videoUrlOverride = '',
  ctaNoTimer = false,
  variant = null,
  pageTitle = 'Get the Heart Health Collection today!',
  pageDescription = 'Get unlimited access to interviews with heart health experts, health-promoting recipes, and more!',
  shareUrl = 'https://health.foodrevolution.org/heart/join/',
  shareImg = 'https://cdn.foodrevolution.org/thc/og/b-hhc-open_graph-1200x1200.png',
  facebookDescription = 'Did you know if disease or injury weakens your heart, your body’s organs will not receive enough blood to function properly? Learn how to protect this vital organ here:',
  twitterDescription = 'Did you know if disease or injury weakens your heart, your body’s organs will not receive enough blood to function properly? Learn how to protect this vital organ here:',
  twitterImg = 'https://cdn.foodrevolution.org/thc/og/b-hhc-open_graph-1200x630.png',
  pinterestDescription = 'Did you know if disease or injury weakens your heart, your body’s organs will not receive enough blood to function properly? Learn how to protect this vital organ here:',
  pinterestImage = 'https://cdn.foodrevolution.org/thc/og/b-hhc-open_graph-1000x1500.png',
  ...props
}) {
  const {
    phases: {
      heart: heartSchedule = {
        phase: 'before',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  const salesFullPrice = !ctaOverride || ctaOverride === 'fullPrice';
  const salesEnd = new Date(heartSchedule.salesEnd).getTime();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'B-HHC',
      sku: 'B-HHC',
      name: 'Heart Health Collection',
      variant: !salesFullPrice ? 'hhcollection-special' : 'hhcollection',
      value: !salesFullPrice ? 47 : 97,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  function HeartCheckout({
    iconColor = 'green',
    scrollTo = 'hhc-cta',
    btnText = 'Start Now',
    timerClass = null,
    className = null,
    ...props
  }) {
    return (
      <div className={classNames(`checkout-wrap`, className)}>
        <h2 className="section-heading mb-3">
          {!salesFullPrice ? (
            <>
              Right Now Just <del>$97</del> $47
            </>
          ) : (
            <>Right Now $97</>
          )}
        </h2>

        {ctaNoTimer || salesFullPrice ? null : (
          <>
            <div className="cta-timer mb-3">
              <div className="offer-box-timer-text">Act now to save $50. The sale expires in:</div>
              <CountdownTimer className={timerClass} time={salesEnd} />
            </div>
          </>
        )}
        {variant === 'ad' ? (
          <h5 className="mt-0">Special very limited time offer: Get it now for a 50% savings!</h5>
        ) : null}

        <CheckoutButton
          sku="B-HHC"
          price={!salesFullPrice ? '47' : '97'}
          slug={!salesFullPrice ? 'hhcollection-special' : 'hhcollection'}
        >
          Start Now
        </CheckoutButton>
        <SalesDisclaimer iconColor={iconColor} />
      </div>
    );
  }

  function SalesCtaBox(props) {
    return (
      <>
        {!salesFullPrice ? (
          <>
            {ctaNoTimer ? (
              <HhcSales47 {...props} btnText="Start Now" />
            ) : (
              <HhcSales47 timerText="Your 50% discount expires in:" date={salesEnd} {...props} btnText="Start Now" />
            )}
          </>
        ) : (
          <HhcSales97 {...props} btnText="Start Now" />
        )}
      </>
    );
  }

  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={shareImg} />
      <div className="page-content">
        <Header
          className="background-purple header-wrap"
          logoWhite
          style="dark"
          pageDescription={pageDescription}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <Section id="hhc-header" color="black" className="section-angle-first" innerClass="text-white text-center">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">The Heart Health Collection</h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <Video
                  url={
                    !salesFullPrice
                      ? 'https://www.youtube.com/watch?v=YuLzE_R_tOM'
                      : 'https://www.youtube.com/watch?v=fufMqnX5810'
                  }
                  playing
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="mt-4 mb-3">
                  Take Control of Your Health <br className="d-none d-sm-block" />
                  with the Heart Health Collection.
                </h3>
                <p>Keep your heart healthy with intelligent food and lifestyle choices.</p>
                <HeartCheckout iconColor="white" />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-questioning"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1307458765.jpg')}
        >
          <h2 className="section-heading">{variant === 'ad' ? <>Sound Familiar?</> : <>Are you...</>}</h2>
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>
              Worried about heart disease because it runs in your family?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Showing signs of heart disease — like high cholesterol or hypertension?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Confused about how to eat for the best heart health?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Unsure of which vitamins and nutrients can help you stay heart healthy?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Wondering what your options are besides medications and surgeries?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Feeling too young to be getting old?</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-urgent"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1312649315.jpg')}
        >
          <p>
            <b>This is urgent.</b> High blood pressure, atherosclerosis, arrhythmia, heart attack, heart failure,
            stroke, and other cardiovascular diseases are devastating millions of lives. Last year, cardiovascular
            diseases were <i>by far</i> the #1 killer on the PLANET (yes — more than COVID-19). And most people don’t
            even know they have cardiovascular disease until it’s too late.
          </p>
          <h4 className="mt-0">
            The good news is that MOST heart disease can be prevented — and even reversed — when you make the right diet
            and lifestyle choices.
          </h4>
          <h4 className="mt-0">(And say “no” to the wrong ones!)</h4>
        </SectionImage>

        <SectionImage
          id="hhc-fate"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1269311298.jpg')}
        >
          <h2 className="section-heading">
            Heart disease <i>doesn’t</i> <br className="d-none d-sm-block d-lg-none d-xl-block" />
            have to be your fate.
          </h2>
          <p>We all know someone who’s had their life tragically cut short by heart disease.</p>
          <p>But while some people show signs of heart disease as early as their 20s...</p>
          <p>
            Others live a long life without ever having to worry about taking multiple medications, chest pain,
            scheduling surgeries, or setting foot in a cardiologist’s office.
          </p>
          <p>
            The scientific data is in. It’s <i>not</i> luck. It’s <i>not</i> genetics.
          </p>
          <p>The sooner you learn what top preventive cardiologists want you to know, the better your chances.</p>
        </SectionImage>

        <SectionImage
          id="hhc-healthy"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1320221438.jpg')}
        >
          <h2 className="section-heading">
            We all want <br className="d-none d-lg-block d-xl-none" />
            to stay healthy…
          </h2>
          <p>But very few of us are taught how to keep our hearts strong, the natural way.</p>
          <p>
            Few of us get specific instructions in school (sadly, even most doctors aren’t taught nutrition in medical
            school). Many internet searches produce outright <i>mis</i>information. And there’s no shortage of articles
            and videos that really just amount to advertisements trying to sell you the newest health fad.
          </p>
          <p>
            <b>
              It can be hard to know who to trust, and what actually makes a difference when it comes to heart health.
            </b>
          </p>
        </SectionImage>

        <SectionImage
          id="hhc-choices-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1158507800.jpg')}
        >
          <h2 className="section-heading">
            Every single day you make countless choices that either make your heart stronger,{' '}
            <br className="d-none d-sm-block d-md-none" />
            or damage it.
          </h2>
          <p>
            Eating a whole-foods diet rich in certain vegetables, fruits, and a few other natural foods is what your the
            heart craves...
          </p>
          <p>
            <b>
              And it’s 100% worth it, because not only will you eat the ultimate heart health diet — these foods can
              lead to the many benefits of total health, including:
            </b>
          </p>
          <IconList>
            <IconListItem>Keeping cholesterol in a healthy range</IconListItem>
            <IconListItem>Normal triglycerides</IconListItem>
            <IconListItem>Maintaining a healthy weight</IconListItem>
            <IconListItem>Creak-free joints</IconListItem>
            <IconListItem>Great energy</IconListItem>
            <IconListItem>Deep sleep</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-choices-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/890382766.jpg')}
        >
          <IconList className="my-0">
            <IconListItem>Happy moods</IconListItem>
            <IconListItem>Healthy sexual function</IconListItem>
            <IconListItem>A sharp mind</IconListItem>
            <IconListItem>Clean lungs</IconListItem>
            <IconListItem>Less bloating and gas</IconListItem>
            <IconListItem>Smooth skin</IconListItem>
            <IconListItem>And more…</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="hhc-studies" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10">
                <h2 className="section-heading text-center">
                  What Long-Running, Double-Blind <br className="d-none d-xl-block" />
                  Clinical Studies Say
                </h2>
                <p className="text-center mb-5">
                  Thousands of nutritional studies are published every year,{' '}
                  <br className="d-none d-sm-block d-lg-none d-xl-block" />
                  with mounds of evidence on the best diet for heart health.
                </p>
                <ImgCard img={require('static/backgrounds/637856490-wide.jpg')}>
                  Harvard researchers tracked diet and health information from 120,000 men and women in two long-running
                  trials. The study found that people who replaced saturated fat with legumes, whole grains, and
                  whole-food carbohydrates substantially lowered their risk of heart disease.<sup>1</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1203599923-wide.jpg')}>
                  A randomized trial published in the <i>New England Journal of Medicine</i> found that eating more
                  vegetables led to a 42% reduction in heart disease-related death.<sup>2,3</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1281479807-wide.jpg')}>
                  Dr. Ornish’s Lifestyle Heart Trial saw an 82% reduction in coronary atherosclerosis after only one
                  year on the Ornish program, which featured a plant-based diet, without the use of statins or
                  cholesterol-lowering drugs.<sup>4</sup>
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1347628652-wide.jpg')}>
                  A study at Cleveland Clinic found that out of 22 patients, all of whom suffered from severe heart
                  disease, 17 stuck to the diet and stopped the progression of disease, and four experienced a complete
                  reversal.<sup>5</sup>
                </ImgCard>
                <h3 className="mt-4 text-center">
                  That means that with the RIGHT food, actions, and information, you <i>can</i> change your life.
                </h3>
                <p className="text-center">We’re all busy, trying to balance the demands of modern life.</p>
                <p className="text-center">
                  But even smart people who eat mostly healthfully aren’t always getting the variety of nutrients they
                  need — it’s not enough to quit smoking and cut down on red meat.
                </p>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-questions-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1195018048.jpg')}
        >
          <h2 className="section-heading">Questions persist like…</h2>
          <IconList iconColor="purple">
            <IconListItem icon={faQuestionCircle}>Which are the very best heart health foods?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What’s the best diet? Paleo, intermittent fasting, keto, Mediterranean, plant-based?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>How much do you really need to exercise?</IconListItem>
            <IconListItem icon={faQuestionCircle}>What foods should you avoid?</IconListItem>
            <IconListItem icon={faQuestionCircle}>How can you manage high blood pressure?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Should you be concerned about your triglycerides?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Is fruit okay, or is all sugar bad?</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-questions-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1266704391.jpg')}
        >
          <IconList iconColor="purple" className="my-0">
            <IconListItem icon={faQuestionCircle}>
              Is epigenetics real? Can you really flip certain genes on or off?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Should you eat raw food?</IconListItem>
            <IconListItem icon={faQuestionCircle}>Do you need to eat organic 100% of the time?</IconListItem>
            <IconListItem icon={faQuestionCircle}>What should you never eat?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              Are supplements worth it or can you get what you need from food?
            </IconListItem>
            <IconListItem icon={faQuestionCircle}>Should you cut out coffee or alcohol?</IconListItem>
            <IconListItem icon={faQuestionCircle}>
              What are the common mistakes people make when eating to support their heart?
            </IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-help"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1296507353.jpg')}
        >
          <h3 className="section-heading">
            We now know the foods that can help you create a healthy heart (and the foods you need to avoid).
          </h3>
          <p>
            We’ve brought together the world’s top heart health experts to teach you what to eat and do for a healthy
            heart.
          </p>
        </SectionImage>

        <Section id="hhc-intro" color="white">
          <Container>
            <Row className="text-center">
              <Col>
                <h3 className="section-heading">
                  YOUR BODY <i>WANTS</i> TO BE WELL
                </h3>
                <p>Give it what it needs to stay healthy and thrive.</p>
                <p className="mt-4 mb-2">
                  <i>Introducing the</i>
                </p>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  style={{maxHeight: '75px'}}
                />
                <h2 className="mt-0 mb-4">Heart Health Collection</h2>
                <Row className="justify-content-center">
                  <Col xs="10" lg="7">
                    <img src="https://cdn.foodrevolution.org/thc/hhc-product-image.png" />
                  </Col>
                </Row>
                <p>
                  Everything you need to activate your body’s ability to heal and put your health in your own hands —
                  where it belongs.
                </p>
                <HeartCheckout className="mt-5" btnText="Get Started" timerClass="countdown-dark" />
              </Col>
            </Row>
            <Row className="justify-content-center text-h5 mt-5 pt-5">
              <Col lg="10">
                <ImgCard img={require('static/backgrounds/987130374-wide.jpg')}>
                  Eat the ultimate heart health diet.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1322056097-wide.jpg')}>
                  Avoid foods and habits that deplete heart health.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1313435329-wide.jpg')}>
                  Understand what the latest scientific <br className="d-none d-xl-block" />
                  evidence recommends.
                </ImgCard>
                <ImgCard img={require('static/backgrounds/1136727937-wide.jpg')}>
                  Feed your cells what they need to protect you.
                </ImgCard>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="hhc-inside" color="light-gray">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <h2 className="section-heading text-center">
                  What’s Inside The <br className="d-none d-sm-block" />
                  Heart Health Collection
                </h2>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/hhc-speakers.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">4 Interviews With Heart Health Experts</h4>
                    <p>
                      In this one-of-a-kind collection, you get exclusive interviews and transcripts with four leading
                      cardiologists, including multiple <i>New York Times</i> best-selling authors and world-respected
                      researchers.
                    </p>
                    <p>
                      Three of the interviews are audio-only, conducted by 2 million-copy bestselling author John
                      Robbins, and the fourth is video and audio and is conducted by Food Revolution Network CEO Ocean
                      Robbins.
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/thc/hhc-transcripts.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Written Transcripts</h4>
                    <p>
                      No need to write everything down — we’ve done it for you. Each interview is fully transcribed, so
                      you can read through the material quickly, revisit important sections, and download to your
                      computer for easy reference.
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <h4 className="section-heading">6 Heart Healthy Recipes</h4>
                <p>
                  The scientific studies are in: Eating the right foods is one of the best things you can do for your
                  heart. This crowd-pleasing collection of recipes are quick, easy, and provide maximum nourishment for
                  your heart and overall health.
                </p>
                <CollectionRecipes id="hhc" className="mt-4" />
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/heart-food-202203.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">
                      <i>Heart Food: 15 Superfoods Your Heart Will Love</i> Guidebook
                    </h4>
                    <p>
                      Are you eating the best foods for a healthy heart? Most people aren’t. It’s time to separate fact
                      from fiction. Start eating the top 15 foods your heart loves, and put them into action
                      (deliciously!) in your life.
                    </p>
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row className="justify-content-center align-items-center">
                  <Col xs="10" sm="8" md="6" lg="3" className="mb-4 mb-lg-0">
                    <img src="https://cdn.foodrevolution.org/global/guidebook/hhc-workbook.png" />
                  </Col>
                  <Col xs="12" lg="9">
                    <h4 className="section-heading">Heart Health Workbook</h4>
                    <p>
                      Learning what to do is the first step… but remembering it is even more important. We designed this
                      comprehensive workbook to help you retain and maximize everything you learn from the Heart Health
                      Collection. Test your knowledge, take notes, and absorb the information quickly as you follow
                      along.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-benefit"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1322712919.jpg')}
        >
          <h3 className="section-heading">Heart Health, Simplified</h3>
          <IconList>
            <IconListItem>Listen any time on your phone or computer.</IconListItem>
            <IconListItem>Read the written transcripts.</IconListItem>
            <IconListItem>Make delicious, immune-healthy meals.</IconListItem>
            <IconListItem>Learn from trusted sources.</IconListItem>
            <IconListItem>Enjoy unlimited access.</IconListItem>
            <IconListItem>Go at your own pace.</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-about-1"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1157237485.jpg')}
        >
          <h4 className="section-heading">Get the Truth About…</h4>
          <IconList>
            <IconListItem>Healing Arteries with Nutrition</IconListItem>
            <IconListItem>How to Switch to a Heart-Healthy Menu</IconListItem>
            <IconListItem>Lifestyle Changes for Living Longer</IconListItem>
            <IconListItem>Erectile Dysfunction and Heart Disease</IconListItem>
            <IconListItem>The Effects of Fast Food on the Heart</IconListItem>
            <IconListItem>Butter, Milk, and Cheese</IconListItem>
            <IconListItem>Dangers of Ketogenic Diets</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-about-2"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1142910868.jpg')}
        >
          <IconList className="my-0">
            <IconListItem>Why Everyone Needs to Know About TMAO</IconListItem>
            <IconListItem>Healthy Snacking</IconListItem>
            <IconListItem>Protein and Your Heart</IconListItem>
            <IconListItem>Managing High Blood Pressure Naturally</IconListItem>
            <IconListItem>Coconut Oil and Heart Health</IconListItem>
            <IconListItem>Leafy Greens and Heart Disease</IconListItem>
            <IconListItem>Non-Surgical Approaches to Heart Disease</IconListItem>
            <IconListItem>Recovering from a Heart Attack</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="hhc-teachers" color="white">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">Your Teachers…</h2>
                <CollectionSpeaker id="hhc" />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="ihc-cta" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-learn-1"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/891026540.jpg')}
        >
          <h2 className="section-heading">More Of What You’ll Learn:</h2>
          <IconList>
            <IconListItem>
              Are you eating enough fruits and vegetables? Which ones are most important for heart health?
            </IconListItem>
            <IconListItem>Common mistakes people make when switching to a plant-centered diet.</IconListItem>
            <IconListItem>How to heal from heart disease naturally.</IconListItem>
            <IconListItem>Which foods have been proven to help open up clogged arteries.</IconListItem>
            <IconListItem>When supplements can be harmful.</IconListItem>
            <IconListItem>The Nobel Prize award-winning research that may save your life.</IconListItem>
          </IconList>
        </SectionImage>

        <SectionImage
          id="hhc-learn-2"
          color="white"
          imgPosition="left"
          imgUrl={require('static/backgrounds/450016927.jpg')}
        >
          <IconList className="my-0">
            <IconListItem>Why cholesterol numbers are sometimes misleading.</IconListItem>
            <IconListItem>
              The most powerful heart health vegetables — and which foods to eliminate from your diet right away.{' '}
            </IconListItem>
            <IconListItem>How much salt is too much?</IconListItem>
            <IconListItem>Who needs to limit protein — and starting at what age?</IconListItem>
            <IconListItem>
              What is TMAO, why is it so strongly associated with heart attacks, strokes, and heart failure?
            </IconListItem>
            <IconListItem>And much more…</IconListItem>
          </IconList>
        </SectionImage>

        <Section id="hhc-guarantee" color="light-gray">
          <Container>
            <Row>
              <Col>
                <GuaranteeBox
                  title={
                    <>
                      You’re Protected By <br className="d-none d-md-block d-lg-none" />
                      Our 60-Day, Unconditional, <br className="d-none d-md-block d-lg-none" />
                      Money-Back Guarantee.
                    </>
                  }
                >
                  <p>
                    <b>Try it out.</b> If you aren't completely blown away, even if you{' '}
                    <br className="d-none d-sm-block d-md-none" />
                    just don’t like it, just email us at{' '}
                    <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a>, any time within 60 days
                    after purchase, for a prompt and courteous refund.
                  </p>
                  <p>
                    This means you can even listen to everything, take in every lesson, and{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    make every single mouth-watering recipe, and if it doesn’t rock your world,{' '}
                    <br className="d-none d-lg-block d-xl-none" />
                    you get every penny back.
                  </p>
                  <p>
                    That’s how confident we are that you’ll LOVE The <br className="d-none d-sm-block d-lg-none" />
                    Heart Health Collection!
                  </p>
                  <p>
                    PLUS, if for any reason you request a refund, the entire Heart Health Collection{' '}
                    <br className="d-none d-lg-block d-xl-none" />— everything that you’ve downloaded, and everything
                    you’ve learned — is still <br className="d-none d-lg-block d-xl-none" />
                    yours to keep.
                  </p>
                  <p>That’s why we call our guarantee “better than money back.”</p>
                </GuaranteeBox>
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-foryou"
          color="white"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1336363998.jpg')}
        >
          <h2 className="section-heading">
            The Heart Health Collection <br className="d-none d-sm-block d-lg-none d-xl-block" />
            is For You If…
          </h2>
          <p>
            <i>You</i> want to do more than just leave your health up to chance.
          </p>
          <p>
            <i>You</i> don’t want to take the word of the mainstream medical industry as your only option.
          </p>
          <p>
            <i>You’re</i> open to new ideas that can maximize how good you feel every day.
          </p>
          <p>
            <i>You</i> want to help others in your family and community be as healthy as possible.
          </p>
          <p>
            <i>You’re</i> ready to stop the myth that heart medications and surgeries are the only things that can save
            us.
          </p>
          <p>
            <i>You</i> believe that all-natural vegetables, fruits, seeds, nuts, and grains have the power to impact
            your health in very good ways.
          </p>
        </SectionImage>

        <SectionImage
          id="hhc-protecting"
          color="light-gray"
          imgPosition="left"
          imgUrl={require('static/backgrounds/1344526137.jpg')}
        >
          <h2 className="section-heading">
            Your Health Is <br className="d-none d-lg-block d-xl-none" />
            Worth Protecting
          </h2>
          <p>Imagine knowing exactly what to eat, based on what reputable scientific studies show us.</p>
          <p>Ending the confusion — and never falling prey to fads.</p>
          <p>Enjoying energy to do what’s important to you, with the people you love.</p>
          <p>Cooking meals that deeply nourish every cell.</p>
          <p>Healing from heart disease, even if you’ve been told that your odds of recovery are poor.</p>
          <p>Having a solid shot at eliminating the need for medications (and their side effects!).</p>
          <p>Easily putting what you know into action.</p>
          <p>
            Living a long, healthy, happy life — without fear that you’re skipping important information that’s vital to
            your health.
          </p>
          <p>Helping your family make the best food and lifestyle decisions.</p>
        </SectionImage>

        <Section id="hhc-faq" color="white">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Frequently Asked Questions</h3>
                <FaqList list={faqHHC} />
              </Col>
            </Row>
          </Container>
        </Section>

        <SectionImage
          id="hhc-everyday"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1327703255.jpg')}
        >
          <h2 className="section-heading">
            You eat every day. <br className="d-none d-sm-block d-md-none d-lg-block" />
            So make it count!
          </h2>
          <p>
            Every person has the ability to reduce their risk significantly with natural food and a few easy{' '}
            <br className="d-none d-lg-block d-xl-none" />
            lifestyle shifts.
          </p>
          <p>
            You don’t have to eat “perfectly” 100% of the time <br className="d-none d-lg-block d-xl-none" />— but most
            people feel the difference in their energy, productivity, sleep, and happiness level when they eat more
            foods for brain health and adopt the best <br className="d-none d-lg-block d-xl-none" />
            lifestyle practices.
          </p>
          <p>
            Pretty soon, your taste buds change and it’s not <br className="d-none d-lg-block d-xl-none" />
            unusual to start craving greens instead of junk food.
          </p>
          <p>
            The most important thing is to stop living in fear <br className="d-none d-lg-block d-xl-none" />
            and to give your brain the natural, powerful, healthy foods it loves.
          </p>
        </SectionImage>

        <Section id="bhc-cta-2" color="gradient-indigo-purple-right">
          <Container>
            <Row>
              <Col>
                <SalesCtaBox trees />
              </Col>
            </Row>
          </Container>
        </Section>

        <Section id="bhc-resources" color="light-gray" className="section-angle-last" angle="none">
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Resources:</h3>

                <ul className="list-references list-group text-small mb-0">
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[1]</div>
                    <div>
                      Li Y, Hruby A, Bernstein AM, Ley SH, Wang DD, Chiuve SE, Sampson L, Rexrode KM, Rimm EB, Willett
                      WC, Hu FB. Saturated Fats <br className="d-none d-xl-block" />
                      Compared With Unsaturated Fats and Sources of Carbohydrates in Relation to Risk of Coronary Heart
                      Disease: A Prospective Cohort Study. <br className="d-none d-xl-block" />
                      <i>J Am Coll Cardiol.</i> 2015 Oct; 66(14):1538-1548. doi: 10.1016/j.jacc.2015.07.055.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[2]</div>
                    <div>
                      Estruch R, Ros E, Salas-Salvadó J, Covas M, Corella D, Arós F, Gómez-Gracia E, et al. Primary
                      Prevention of Cardiovascular Disease with a Mediterranean Diet.{' '}
                      <br className="d-none d-xl-block" />
                      <i>N Engl J Med</i> 2013; 368:1279-1290. doi: 10.1056/NEJMoa1200303
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[3]</div>
                    <div>
                      Estruch R, Ros E, Salas-Salvadó J, Covas M, Corella D, Arós F, Gómez-Gracia E, et al. Primary
                      Prevention of Cardiovascular Disease with a Mediterranean Diet{' '}
                      <br className="d-none d-xl-block" />
                      Supplemented with Extra-Virgin Olive Oil or Nuts. <i>N Engl J Med</i> 2018; 378:e34doi:
                      10.1056/NEJMoa1800389.
                    </div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[4]</div>
                    <div>https://www.thelancet.com/journals/lancet/article/PII0140-6736(90)91656-U/fulltext</div>
                  </li>
                  <li className="list-group-item reference-item d-flex align-items-center p-3">
                    <div className="pr-3">[5]</div>
                    <div>https://dresselstyn.com/JFP_06307_Article1.pdf</div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          pageDescription={pageDescription}
          shareUrl={shareUrl}
          shareImg={shareImg}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
      {/*noExitIntent ? null : <ModalExitHeart version={!salesFullPrice ? 'salesPrice' : 'fullPrice'} />*/}
    </div>
  );
}
