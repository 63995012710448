import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import CancerSalesIndex from './join';
import track from 'utils/track';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'B-ACC',
        sku: 'B-ACC',
        name: 'Anti-Cancer Collection',
        variant: 'acc-special',
        value: 47,
        currency: 'usd'
      }),
    []
  );
  return <CancerSalesIndex ctaOverride="salesPrice" ctaNoTimer />;
}
