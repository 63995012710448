

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404': require('/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/brain/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/brain/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/brain/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/brain/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/cancer/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/cancer/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/cancer/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/cancer/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/immunity/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/immunity/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/immunity/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/immunity/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/brain/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/brain/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/brain/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/brain/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/brain/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/brain/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/brain/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/brain/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/cancer/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/cancer/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/cancer/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/cancer/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/cancer/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/cancer/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/cancer/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/cancer/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/heart/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/heart/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/heart/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/heart/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/heart/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/heart/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/heart/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/heart/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/immunity/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/immunity/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/immunity/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/immunity/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/immunity/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/immunity/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/immunity/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/immunity/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/thc-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/thc-upsell.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/node_modules/react-static/lib/browser/components/Default404'

